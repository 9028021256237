.main-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
}

.central-form {
  max-width: 1100px;
  width: 90%;
  border-radius: 12px;
  overflow: hidden;
  background-color: #f0f0f0;
  box-shadow: 0 0px 20px -2px #1f2235;
}

.central-form-header {
  padding: 30px;
  background-image: url("https://res.cloudinary.com/dhqh98spd/image/upload/v1702114331/bg-two_mibuvi.webp");
  background-size: cover;
  color: white;
  text-align: center;
}

.central-form-header h2 {
  color: white;
  font-family: "Poppins Bold";
  font-size: 32px;
  margin-bottom: 15px;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.social-icons a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  transition: all 0.3s ease;
}

.social-icons a:hover {
  background-color: var(--dark-orange);
  transform: translateY(-5px);
}

.social-icons .fa {
  font-size: 22px;
}

.form-container {
  padding: 30px;
}

.contact-form {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 98%;
  margin: 0 auto;
}

.form-status {
  text-align: center;
  margin-bottom: 20px;
  color: var(--dark-orange);
  font-family: "Poppins SemiBold";
  min-height: 24px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-family: "Poppins SemiBold";
  color: var(--main-dark-blue);
  margin-bottom: 8px;
}

.form-input {
  width: 100%;
  padding: 12px 15px;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  font-family: "Poppins Regular";
  font-size: 16px;
  color: var(--black);
  transition: all 0.3s ease;
}

.form-input:focus {
  border-color: var(--dark-orange);
  box-shadow: 0 0 0 2px rgba(255, 88, 35, 0.2);
  outline: none;
}

textarea.form-input {
  min-height: 120px;
  resize: vertical;
}

.submit-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 12px 30px;
  background-color: var(--dark-orange);
  color: white;
  border: none;
  border-radius: 30px;
  font-family: "Poppins SemiBold";
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 5px 15px rgba(255, 88, 35, 0.3);
}

.submit-btn:hover {
  background-color: var(--main-dark-blue);
  transform: translateY(-3px);
  box-shadow: 0 8px 20px rgba(255, 88, 35, 0.4);
}

.submit-btn .fa {
  margin-left: 8px;
}

.loader {
  display: flex;
  justify-content: center;
}

.loader img {
  width: 30px;
  height: 30px;
}

/* Media Queries */

@media screen and (max-width: 480px) {
  .central-form-header h2 {
    font-size: 24px;
  }
  
  .contact-form {
    padding: 20px;
  }
  
  .form-input {
    padding: 10px;
    font-size: 14px;
  }
  
  .submit-btn {
    width: 100%;
    padding: 10px 20px;
  }
}