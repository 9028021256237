.profile-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 20px 50px;
  background-image: url("https://res.cloudinary.com/dhqh98spd/image/upload/v1702114331/bg-two_mibuvi.webp");
  background-size: cover;
  position: relative;
}

.profile-content {
  max-width: 1200px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}

.profile-details {
  flex: 1;
  padding: 40px;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  border-radius: 16px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  animation: fadeIn 1s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.profile-picture {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 500px;
  animation: fadeInRight 1s ease-out;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.profile-picture-background {
  width: 380px;
  height: 380px;
  border-radius: 50%;
  background-image: url("https://res.cloudinary.com/dhqh98spd/image/upload/v1700126064/profilephoto_nfiptw.svg");
  background-size: cover;
  background-position: center;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
  transition: transform 0.5s ease, box-shadow 0.5s ease;
  border: 8px solid white;
}

.profile-picture-background:hover {
  transform: scale(1.05);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
}

.profile-greeting {
  font-family: "Poppins Medium", sans-serif;
  font-size: 18px;
  color: var(--main-dark-blue);
  margin-bottom: 10px;
}

.profile-name {
  font-family: "Poppins Bold", sans-serif;
  font-size: 48px;
  color: var(--main-dark-blue);
  margin-bottom: 20px;
  line-height: 1.2;
}

.profile-name .highlighted-text {
  color: var(--dark-orange);
  position: relative;
  display: inline-block;
}

.profile-name .highlighted-text::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 8px;
  background-color: rgba(255, 88, 35, 0.2);
  z-index: -1;
}

.profile-role {
  margin-bottom: 30px;
}

.profile-role h2 {
  font-family: "Poppins SemiBold", sans-serif;
  font-size: 28px;
  color: var(--main-dark-blue);
  margin-bottom: 10px;
  min-height: 40px;
}

.profile-role-tagline {
  font-family: "Poppins Regular", sans-serif;
  font-size: 18px;
  color: #666;
  margin-bottom: 30px;
  line-height: 1.5;
}

.profile-social {
  display: flex;
  gap: 15px;
  margin-bottom: 30px;
}

.profile-social a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: #f5f5f5;
  color: var(--main-dark-blue);
  transition: all 0.3s ease;
}

.profile-social a:hover {
  background-color: var(--dark-orange);
  color: white;
  transform: translateY(-5px);
}

.profile-social .fa {
  font-size: 22px;
}

.profile-actions {
  display: flex;
  gap: 20px;
}

.profile-btn {
  padding: 14px 30px;
  border-radius: 30px;
  border: none;
  font-family: "Poppins SemiBold", sans-serif;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  transition: all 0.3s ease;
}

.profile-btn-primary {
  background-color: var(--dark-orange);
  color: white;
}

.profile-btn-primary:hover {
  background-color: var(--main-dark-blue);
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(255, 88, 35, 0.2);
}

.profile-btn-secondary {
  background-color: white;
  color: var(--main-dark-blue);
  border: 2px solid var(--main-dark-blue);
}

.profile-btn-secondary:hover {
  background-color: var(--main-dark-blue);
  color: white;
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(31, 34, 53, 0.2);
}

/* Responsive Styles */
@media screen and (max-width: 1024px) {
  .profile-content {
    flex-direction: column-reverse;
    gap: 40px;
    text-align: center;
    margin-bottom: 3rem;
  }
  
  .profile-details {
    padding: 30px;
  }
  
  .profile-name {
    font-size: 36px;
  }
  
  .profile-role h2 {
    font-size: 24px;
  }
  
  .profile-social {
    justify-content: center;
  }
  
  .profile-actions {
    justify-content: center;
  }
}

@media screen and (max-width: 768px) {
  .profile-container {
    padding: 120px 20px 50px;
  }
  
  .profile-picture-background {
    width: 280px;
    height: 280px;
  }
  
  .profile-name {
    font-size: 32px;
  }
}

@media screen and (max-width: 480px) {
  .profile-details {
    padding: 25px 20px;
  }
  
  .profile-name {
    font-size: 28px;
  }
  
  .profile-role h2 {
    font-size: 20px;
    min-height: 60px;
  }
  
  .profile-role-tagline {
    font-size: 16px;
  }
  
  .profile-picture-background {
    width: 240px;
    height: 240px;
  }
  
  .profile-actions {
    flex-direction: column;
    gap: 15px;
  }
  
  .profile-btn {
    width: 100%;
  }
}