.footer-container {
    width: 100%;
    margin: 0;
    padding: 0;
    line-height: 0;
  }
  
  .footer-wave {
    display: block;
    width: 100%;
    height: auto;
  }
  
  .footer-wave svg {
    width: 100%;
    height: auto;
    display: block;
  }