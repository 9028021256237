.header-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.header-parent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 15px 30px;
  height: 70px;
}

.header-logo {
  font-size: 28px;
  font-family: "Poppins Bold", sans-serif;
  color: var(--main-dark-blue);
  letter-spacing: 1px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
}

.header-logo span {
  display: inline-block;
  position: relative;
}

.header-logo span::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: var(--dark-orange);
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s ease;
}

.header-logo:hover span::after {
  transform: scaleX(1);
  transform-origin: left;
}

.header-logo-accent {
  color: var(--dark-orange);
}

.header-options {
  display: flex;
  align-items: center;
  gap: 30px;
}

.header-option {
  font-size: 16px;
  font-family: "Poppins Medium", sans-serif;
  cursor: pointer;
  color: var(--black);
  padding: 6px 2px;
  position: relative;
  transition: all 0.3s ease;
}

.header-option::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--dark-orange);
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s ease;
}

.header-option:hover {
  color: var(--dark-orange);
}

.header-option:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}

.selected-header-option {
  color: var(--dark-orange);
}

.selected-header-option::after {
  transform: scaleX(1);
}

/* Header Hamburger Menu */
.header-hamburger {
  display: none;
  cursor: pointer;
  background: none;
  border: none;
  padding: 10px;
}

.hamburger-icon {
  width: 24px;
  height: 18px;
  position: relative;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
}

.hamburger-icon span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: var(--main-dark-blue);
  border-radius: 9px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

.hamburger-icon span:nth-child(1) {
  top: 0px;
}

.hamburger-icon span:nth-child(2) {
  top: 8px;
}

.hamburger-icon span:nth-child(3) {
  top: 16px;
}

.hamburger-icon.open span:nth-child(1) {
  top: 8px;
  transform: rotate(135deg);
  background: var(--dark-orange);
}

.hamburger-icon.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

.hamburger-icon.open span:nth-child(3) {
  top: 8px;
  transform: rotate(-135deg);
  background: var(--dark-orange);
}

/* Responsive Styles */
@media screen and (max-width: 968px) {
  .header-parent {
    padding: 15px 20px;
  }
  
  .header-hamburger {
    display: block;
    z-index: 1100;
  }
  
  .header-options {
    position: fixed;
    top: 0;
    right: -100%;
    width: 250px;
    height: 100vh;
    background-color: white;
    flex-direction: column;
    padding-top: 80px;
    transition: right 0.3s ease;
    box-shadow: -5px 0 15px rgba(0, 0, 0, 0.1);
    gap: 15px;
    align-items: flex-start;
    padding-left: 30px;
  }
  
  .header-options.show-hamburger-options {
    right: 0;
  }
  
  .header-option {
    font-size: 18px;
    width: 100%;
    padding: 12px 0;
  }
}

@media screen and (max-width: 480px) {
  .header-logo {
    font-size: 22px;
  }
}