.home-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    position: relative;
    overflow: hidden;
  }
  
  .wave-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    transform: translateY(-48px);
  }